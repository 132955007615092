import React, { useEffect, useState } from 'react'
import { OPEN_CATS_URL } from '../../config';
import $ from 'jquery';
import moment from 'moment'
import Modal from 'react-modal';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getToken, removeToken, setToken } from '../../storage';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SingleJobOrder from './singleJobOrder';

export default function ViewJobOrder(props) {
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [company, setCompany] = useState([])
    const [modalIsOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([])
    const [editorState, setEditorState] = useState()
    const [message, setMessage] = useState()
    const [jobData, setJobData] = useState({
        status: "Internal",
        jobTitle: "",
        company: "",
        companyName: "",
        startDate: "",
        duration: "",
        jobType: "",
        billRate: "",
        payRate: "",
        city: "",
        state: "",
        work_authorization: "",
        recruiter: "",
        owner: "",
        keySkills: "",
        experience_needed: "",
        submissionDeadline: "",
        bdmComments: "",
        country: "USA",
        internalNotes: "",
        jobContact: -1,
        jobDepartment: "",
        client_job_id: "",
        source: "",
        openings: '1',
        priority: '2 - Do not work on this',
    })
    const [jobContacts, setJobContacts] = useState([])
    const [jobDepartment, setJobDepartment] = useState([])
    const [viewJobOrder, setViewJobOrder] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    // eslint-disable-next-line
    const [filterData, setFilterData] = useState([])
    const [filterType, setFilterType] = useState("")
    const [filterItem, setFilterItem] = useState("")
    const [filterText, setFilterText] = useState("")
    const [tableView, setTableView] = useState(false)
    const [jobTypeData, setJobTypeData] = useState([])
    const [selectedJobType, setSelectedJobType] = useState()
    const [selectedJobOrderId, setSelectedJobOrderId] = useState()
    const [jobModal, setJobModal] = useState()
    const [companyData, setCompanyData] = useState()
    const [companyAdd, setCompanyAdd] = useState(false)
    const [companyContact, setCompanyContact] = useState(false)

    const [contactData, setContactData] = useState({
        company_id: "",
        last_name: "",
        first_name: "",
        title: "",
        email1: "",
        email2: "",
        phone_work: "",
        phone_cell: "",
        phone_other: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        is_hot: "",
        notes: "",
        company_department_id: "0",
        reports_to: "0"
    })
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [reportsOptions, setReportsOptions] = useState([])

    useEffect(() => {
        setMessage("")
    }, [props.showJobOrder])

    useEffect(() => {
        if (modalIsOpen === true) {
            getUsers()
            getCompanies()
            getJobTypesData()
        }

    }, [modalIsOpen])


    const getCompanies = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getCompany`)
            .then((response) => response.json())
            .then((result) => {
                setCompany(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getAllUsers`)
            .then((response) => response.json())
            .then((result) => {
                setUsers(result.data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (data?.length > 0) {
            setTableView(true)
            $(document).ready(function () {
                $('#jobOrder tfoot th').each(function () {
                    var title = $(this).text();
                    $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                });

                var table = window.$('#jobOrder').DataTable(
                    {
                        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                        "order": [[0, "desc"]],
                        mark: true,
                        destroy: true,
                        dom: 'Bfrtip',
                        buttons: [
                            'pageLength',
                            // {
                            //     extend: 'excelHtml5',
                            //     autoFilter: true,
                            //     sheetName: 'Exported data',
                            //     text: 'Export'
                            // }
                        ]
                    }
                )
                // eslint-disable-next-line
                table.columns().every(function () {
                    var that = this;

                    $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });
                });
                $('a.toggle-vis').on('click', function (e) {
                    e.preventDefault();

                    // Get the column API object
                    var column = table.column($(this).attr('data-column'));

                    // Toggle the visibility
                    column.visible(!column.visible());
                });
            })
        }
        // eslint-disable-next-line
    }, [new URLSearchParams(window.location.search)?.get('page'), data])

    useEffect(() => {
        if (viewJobOrder === true) {
            getAllJobs()
        }
        //eslint-disable-next-line
    }, [viewJobOrder])


    const getAllJobs = async () => {
        setLoader(true)
        setTableView(false)
        if (loader) {
            window.$('#jobOrder').DataTable().clear().destroy();
        }
        // if (getToken('joborderData') !== null) {
        //     setLoader(false)
        //     setData(JSON.parse(getToken('joborderData')))
        //     setFilterData(JSON.parse(getToken('joborderData')))
        // } else {
        await fetch(`${OPEN_CATS_URL}/eventRouter/getAllJobs?filterItem=${filterItem}&options=${filterType}&searchKey=${filterText}`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setData(result.data)
                setFilterData(result.data)
                setToken('joborderData', JSON.stringify(result.data))
                setIsFilterOpen(false)
                setFilterText("")
                setFilterType("")
                setFilterItem("")
                if (!loader) {
                    $(document).ready(function () {

                        $('#jobOrder tfoot th').each(function () {
                            var title = $(this).text();
                            $(this).html('<input type="text" placeholder="Search ' + title + '" />');
                        });

                        var table = window.$('#jobOrder').DataTable(
                            {
                                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                                "order": [[0, "desc"]],
                                mark: true,
                                destroy: true,
                                dom: 'Bfrtip',
                                buttons: [
                                    'pageLength',
                                    // {
                                    //     extend: 'excelHtml5',
                                    //     autoFilter: true,
                                    //     sheetName: 'Exported data',
                                    //     text: 'Export'
                                    // }
                                ]
                            }
                        )
                        // eslint-disable-next-line
                        table.columns().every(function () {
                            var that = this;

                            $('input', this.footer()).addClass('input-class-footer').on('keyup change', function () {
                                if (that.search() !== this.value) {
                                    that
                                        .search(this.value)
                                        .draw();
                                }
                            });
                        });
                        $('input.toggle-vis').on('change', function (e) {
                            e.preventDefault()
                            var columnIndex = parseInt($(this).attr('data-column'));
                            table.column(columnIndex).visible(!this.checked);
                        });
                    })
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
        // }
        setLoader(false)

    }

    function closeModal() {
        setIsOpen(false);
        history.push('/home?page=jobOrders&type=view')

    }

    const onEditorStateChange = (editorState) => {

        setEditorState(editorState)
    }

    const handleChange = (e) => {
        setJobData({
            ...jobData,
            [e.target.name]: e.target.value
        })
    }

    const handleCompanyChange = (e) => {
        setCompanyData({
            ...companyData,
            [e.target.name]: e.target.value
        })
    }

    const handleNewJob = async (e) => {
        e.preventDefault();
        if (editorState !== undefined) {
            var rawContentState = convertToRaw(editorState.getCurrentContent());
            var markup = draftToHtml(
                rawContentState,
            );
        }
        setMessage("")
        if (jobData.company !== "") {
            if (selectedJobType?.length > 0) {
                setLoader(true)
                await fetch(
                    `${OPEN_CATS_URL}/eventRouter/createNewJobOrder`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            addedBy: getToken('id'), markup: markup, data: jobData, selectedJobType, job_type_name: selectedJobType.map(x => {
                                return x.label
                            }).toString()
                        }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        // toast.success(result.message,{hideProgressBar: true})
                        setIsOpen(false)
                        // setTimeout(() => {
                        //     window.location.href = "/home?page=jobOrders&type=view"
                        // }, 2000)
                        setJobData({
                            status: "Internal",
                            jobTitle: "",
                            company: "",
                            companyName: "",
                            startDate: "",
                            duration: "",
                            jobType: "",
                            billRate: "",
                            payRate: "",
                            city: "",
                            state: "",
                            work_authorization: "",
                            recruiter: "",
                            owner: "",
                            keySkills: "",
                            experience_needed: "",
                            submissionDeadline: "",
                            bdmComments: "",
                            country: "USA",
                            internalNotes: "",
                            jobContact: -1,
                            jobDepartment: "",
                            client_job_id: "",
                            source: "",
                            openings: '1',
                            priority: '2 - Do not work on this',
                        })
                        setEditorState()
                        setSelectedJobType()
                        setSelectedJobOrderId(result.data)
                        setJobModal(true)
                    })
                    .catch((error) => {

                        setLoader(false)
                    });
            } else {
                toast.warning("Please Select Job Type", {
                    hideProgressBar: true
                })
            }
        } else {
            toast.warning("Please Select Company", {
                hideProgressBar: true
            })

        }


        setLoader(false)



    }

    const getJobDepartment = async (companyId) => {
        setJobDepartment([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobDepartment?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobDepartment(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }

    const getJobContacts = async (companyId) => {
        setJobContacts([])
        await fetch(
            `${OPEN_CATS_URL}/eventRouter/getJobContacts?companyId=${companyId}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 200) {
                    setJobContacts(result.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.error('Error:', error);
            });
    }
    // eslint-disable-next-line
    const handleFilterSearch = () => {
        // if (filterText.length !== undefined) {
        //     setData(filterData.filter((x) => {
        //         if (filterType === 'equal') {
        //             return x?.result1?.companyName?.toLowerCase().includes(filterText?.toLowerCase())
        //         }
        //         if (filterType === 'notEqual') {
        //             return !x?.result1?.companyName?.toLowerCase().includes(filterText?.toLowerCase())
        //         }
        //     }))
        // } else {
        //     setData(filterData)
        // }
    }

    const handleGetJobs = () => {
        getAllJobs()
    }

    const getJobTypesData = async () => {
        setJobTypeData([])
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/searchRouter/getJobTypes`)
            .then((response) => response.json())
            .then((result) => {
                setLoader(false)
                setJobTypeData(result.data)
            })
            .catch((error) => {

                console.log('Error:', error.response);
                setLoader(false)
            });
        setLoader(false)
    }

    const addNewCompany = async (e) => {
        e.preventDefault()
        setLoader(true)
        await fetch(
            `${OPEN_CATS_URL}/companyRouter/createNewCompany`,
            {
                method: 'POST',
                body: JSON.stringify({ addedBy: getToken('id'), data: companyData }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((result) => {
                toast.info(result.message, {
                    hideProgressBar: true
                })
                getCompanies()
                setCompanyAdd(false)
            })
            .catch((error) => {
                setLoader(false)
            });
        setLoader(false)
    }

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('type') === 'add') {
            setIsOpen(true)
            setMessage("")
            setViewJobOrder(false)
        }
        if (new URLSearchParams(window.location.search).get('type') === 'view') {
            setViewJobOrder(true)
            setMessage("")
        }
        // eslint-disable-next-line
    }, [new URLSearchParams(window.location.search).get('type'), props?.key])

    const addNewContact = async (e) => {
        e.preventDefault()
        if (data.company_id === "0") {
            toast.info("Please Select Company", {
                hideProgressBar: true
            })
        } else {
            setLoader(true)
            await fetch(
                `${OPEN_CATS_URL}/contactRouter/createNewContact`,
                {
                    method: 'POST',
                    body: JSON.stringify({ addedBy: getToken('id'), data: contactData }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    toast.info(result.message, {
                        hideProgressBar: true
                    })
                    getJobContacts(jobData.company)
                    setCompanyContact(false)
                })
                .catch((error) => {
                    setLoader(false)
                });
            setLoader(false)
        }

    }

    const handleChangeContact = (e) => {
        setContactData({
            ...contactData,
            [e.target.name]: e.target.value
        })
    }

    const getDepartments = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getDepartments?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setDepartmentOptions(result.data.map(x => {
                    return {
                        label: x.name, value: x.company_department_id
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const getContacts = async (id) => {
        await fetch(
            `${OPEN_CATS_URL}/contactRouter/getContacts?id=${id}`)
            .then((response) => response.json())
            .then((result) => {
                setReportsOptions(result.data.map(x => {
                    return {
                        value: x.contact_id, label: `${x.first_name} ${x.last_name}`
                    }
                }))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (jobData.status === "Internal") {
            setSelectedJobType(jobTypeData.filter(x => {
                return x.job_type === "Bench Sales"
            }).map(x => {
                return {
                    value: x.id, label: x.job_type
                }
            }))
        }
    }, [jobData, jobTypeData])

    return (
        props.showJobOrder && (
            <>
                <ToastContainer />
                <div style={{ margin: 'auto' }}>
                    <label style={{ color: 'green', fontSize: '18px', fontWeight: '500' }}>{message}</label>
                </div>
                {
                    data.length > 0 && viewJobOrder &&
                    <div className='ml-4' style={{ width: '90%', margin: 'auto' }}>
                        <div className="mb-1">
                            <button type="button" className={isFilterOpen ? "btn btn-outline-dark btn-sm mr-2 active" : "btn btn-outline-dark btn-sm mr-2"} onClick={() => {
                                setIsFilterOpen(!isFilterOpen)
                            }}>Filter</button>
                        </div>
                    </div>
                }

                {
                    (viewJobOrder && isFilterOpen) &&
                    <div className='ml-4' style={{ width: '90%', margin: 'auto' }}>
                        <div className="row col-12 mt-1 mb-1">
                            <div className='col-3'>
                                <select value={filterItem} onChange={(e) => setFilterItem(e.target.value)} className='form-control form-control-sm'>
                                    <option key="companyName" value={"companyName"}>Company Name</option>
                                    <option key="joborderStatus" value={"joborderStatus"}>Jobrder Status</option>
                                </select>
                            </div>

                            {
                                filterItem === "companyName" &&
                                <div className='col-3'>
                                    <select value={filterType} onChange={(e) => {
                                        removeToken('joborderData')
                                        setFilterType(e.target.value)
                                    }} className='form-control form-control-sm'>
                                        <option key="Select" value="">Select One</option>
                                        <option key="equal" value="equal">is equal to</option>
                                        <option key="notEqual" value="notEqual">not equal to</option>
                                    </select>
                                </div>
                            }
                            {
                                filterItem === "joborderStatus" &&
                                <div className='col-3'>
                                    <select value={filterType} onChange={(e) => {
                                        removeToken('joborderData')
                                        setFilterType(e.target.value)
                                    }} className='form-control form-control-sm'>
                                        <option value="">Select One</option>
                                        <option value="Active">Active</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Lead">Prospective / Lead</option>
                                        <option value="OnHold">On Hold</option>
                                        <option value="Full">Full</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Canceled">Canceled</option>
                                        <option value="Internal">Internal</option>
                                        <option value="All">All</option>
                                    </select>
                                </div>
                            }

                            {
                                filterItem === "companyName" &&
                                <div className='col-3'>
                                    <input title="Enter company name separated with comma(,)" value={filterText} type="text" onChange={(e) => setFilterText(e.target.value)} className='form-control form-control-sm' />
                                </div>
                            }

                            <div className='col-3'>
                                <button type="button" className={"btn btn-outline-dark btn-sm mr-2"} onClick={() => {
                                    getAllJobs()
                                }}>Apply</button>
                                <button type="button" className={"btn btn-outline-dark btn-sm mr-2"} onClick={() => {
                                    handleGetJobs()
                                }}>Clear Filter</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    viewJobOrder &&
                    <div className="mt-1 mb-1">
                        {
                            loader && (
                                <div style={{ textAlign: 'center' }}>
                                    Please Wait, Loading....
                                </div>
                            )
                        }
                        {
                            tableView &&
                            <div className="row justify-content-center" style={{ width: '90%', margin: 'auto', overflow: 'auto', fontSize: '13px' }}>
                                <div id="dataTable" className="col-auto">
                                    <label>Hide/Unhide Columns</label>
                                    <div style={{ fontSize: '10px' }}>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="1" /> </label><label className='m-1'>ID</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="2" /> </label><label className='m-1'>Cpy Job ID</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="3" /> </label><label className='m-1'>Title</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="4" /> </label><label className='m-1'>Company</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="5" /> </label><label className='m-1'>Status</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="6" /> </label><label className='m-1'>S</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="7" /> </label><label className='m-1'>P</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="8" /> </label><label className='m-1'>Priority</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="9" /> </label><label className='m-1'>Public</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="10" /></label><label className='m-1'> City</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="11" /></label><label className='m-1'> State</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="12" /></label><label className='m-1'> Max Rate</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="13" /></label><label className='m-1'> Pay Rate</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="14" /></label><label className='m-1'> Duration</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="15" /></label><label className='m-1'> Age</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="16" /></label><label className='m-1'> Recruiter</label></label>
                                        <label><label><input type="checkbox" className="toggle-vis" data-column="17" /></label><label className='m-1'> Created Date</label></label>
                                    </div>
                                    <br />
                                    <table id="jobOrder" className="table table-hover table-sm rwd-table" style={{ width: '100%', overflow: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ display: 'none' }}>JobId</th>
                                                <th>ID</th>
                                                <th>Cpy Job ID</th>
                                                <th>Title</th>
                                                <th>Company</th>
                                                <th>Status</th>
                                                <th>S</th>
                                                <th>P</th>
                                                <th>Priority</th>
                                                <th>Public</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Max Rate</th>
                                                <th>Pay Rate</th>
                                                <th>Duration</th>
                                                <th>Age</th>
                                                <th>Recruiter</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th style={{ display: 'none' }}>JobId</th>
                                                <th>ID</th>
                                                <th>Cpy Job ID</th>
                                                <th>Title</th>
                                                <th>Company</th>
                                                <th>Status</th>
                                                <th>S</th>
                                                <th>P</th>
                                                <th>Priority</th>
                                                <th>Public</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Max Rate</th>
                                                <th>Pay Rate</th>
                                                <th>Duration</th>
                                                <th>Age</th>
                                                <th>Recruiter</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                data.map((x) => {
                                                    return (
                                                        <tr key={x?.result1?.joborder_id}>
                                                            <td style={{ display: 'none' }}>{x?.joborder_id}</td>
                                                            <td data-th="ID">{`NOVIJP00${x?.joborder_id}`}</td>
                                                            <td data-th="Cpy Job ID">{x?.client_job_id}</td>
                                                            <td data-th="Title">
                                                                <label
                                                                    style={{ textDecoration: 'underline', color: x?.is_hot === 1 ? "red" : "black", cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setSelectedJobOrderId(x?.joborder_id)
                                                                        setJobModal(true)
                                                                    }
                                                                    }
                                                                >
                                                                    {x?.title}
                                                                </label>
                                                            </td>
                                                            <td data-th="Company">{x?.companyName}</td>
                                                            <td data-th="Status">{x?.status}</td>
                                                            <td data-th="S">{x?.s}</td>
                                                            <td data-th="P">{x?.p}</td>
                                                            <td data-th="Priority">{x?.Priority}</td>
                                                            {/* <td><SubmissionCount id={x.joborder_id} /></td> */}
                                                            {/* <td><PipeLineCount id={x.joborder_id} /></td> */}
                                                            <td data-th="Public">{x?.public === 1 ? "Yes" : "No"}</td>
                                                            <td data-th="City">{x?.city}</td>
                                                            <td data-th="State">{x?.state}</td>
                                                            <td data-th="Max Rate">{x?.rate_max}</td>
                                                            <td data-th="Pay Rate">{x?.pay_rate}</td>
                                                            <td data-th="Duration">{x?.duration}</td>
                                                            {/* <td><PayRate id={x.joborder_id} /></td> */}
                                                            <td data-th="Age">{moment(x?.date_created).fromNow()}</td>
                                                            <td data-th="Recruiter">{`${x?.createdFirstName} .${x?.createdLastName}`}</td>
                                                            <td data-th="Created Date">{moment(x?.date_created).format("MM-DD-YYYY, h:mm:ss")}</td>
                                                        </tr>

                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >


                        <form className="form-group" onSubmit={(e) => handleNewJob(e)}>
                            <div className="form-group">
                                <button className="btn btn-danger btn-sm float-right" type="button" onClick={closeModal}>close</button>
                                <button className="btn btn-outline-dark btn-sm float-right mr-2" type="submit"  >Create Job</button>
                            </div>
                            <label>New Job Order</label>

                            <div className="row mt-3 ncof">
                                <div className="form-group col-4">
                                    <label>Status*</label>
                                    <select name="status" className="form-control form-control-sm nlfj" onChange={(e) => {
                                        if (e.target.value === 'Internal') {
                                            setJobData({
                                                ...jobData,
                                                openings: '1',
                                                priority: '2 - Do not work on this',
                                                status: e.target.value
                                            })
                                        } else {
                                            setJobData({
                                                ...jobData,
                                                openings: '',
                                                priority: '',
                                                status: e.target.value
                                            })
                                            setSelectedJobType()
                                        }

                                    }} required>
                                        <option value="Active">Active</option>
                                        <option value="Active">Active</option>
                                        <option value="Upcoming">Upcoming</option>
                                        <option value="Lead">Prospective / Lead</option>
                                        <option value="OnHold">On Hold</option>
                                        <option value="Full">Full</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Canceled">Canceled</option>
                                        <option selected={true} value="Internal">Internal</option>
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Job Title*</label>
                                    <input type="text" name="jobTitle" className="form-control form-control-sm nlfj" placeholder="Job Title" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group col-4 ">
                                    <label>Company*</label><label className='nlf' onClick={() => setCompanyAdd(true)} style={{ fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' }}>(Add New Company)</label>
                                    <br />
                                    <Select
                                        placeholder="Select Company"
                                        name="company"
                                        className="nlfj"
                                        onChange={(selectedOption) => {
                                            setJobData({
                                                ...jobData,
                                                'company': selectedOption.value,
                                                'companyName': selectedOption.label
                                            })
                                            getJobDepartment(selectedOption.value)
                                            getJobContacts(selectedOption.value)
                                        }}
                                        options={company.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
                                            return {
                                                value: x.company_id, label: x.name
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-4">
                                    <label>Country*</label>
                                    <input type="text" name="country" value={jobData.country} className="form-control form-control-sm mt-1 mb-2 nlfj" onChange={(e) => handleChange(e)} placeholder="Country" required={jobData.status !== 'Internal'} />
                                </div>
                                <div className="form-group col-4">
                                    <label>State*</label>
                                    <input type="text" name="state" value={jobData.state} className="form-control form-control-sm mt-1 mb-2 nlfj" onChange={(e) => handleChange(e)} placeholder="State" required={jobData.status !== 'Internal'} />
                                </div>
                                <div className="form-group col-4">
                                    <label>City*</label>
                                    <input type="text" name="city" value={jobData.city} className="form-control form-control-sm mt-1 mb-2 nlfj" onChange={(e) => handleChange(e)} placeholder="City" required={jobData.status !== 'Internal'} />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-4">
                                    <label>Job Source*</label>
                                    <input type="text" name="source" className="form-control form-control-sm nlfj" placeholder="Job Source" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group col-4">
                                    <label>Job Type*</label>
                                    <Select
                                        isMulti
                                        value={selectedJobType}
                                        className="nlfj"
                                        onChange={(selectedOption) => setSelectedJobType(selectedOption)}
                                        options={jobTypeData.map(x => {
                                            return {
                                                value: x.id, label: x.job_type
                                            }
                                        })}
                                    />
                                </div>
                                <div className="form-group col-4">
                                    <label>Contact</label><label onClick={() => {
                                        if (jobData.company !== "") {
                                            setContactData({
                                                ...contactData,
                                                company_id: jobData.company
                                            })
                                            getDepartments(jobData.company)
                                            getContacts(jobData.company)
                                            setCompanyContact(true)
                                        } else {
                                            toast.warn("Please Select Company", {
                                                hideProgressBar: true
                                            })
                                        }

                                    }} style={{ fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' }}>{" "}(Add New Contact)</label>
                                    <select name="jobContact" className="form-control form-control-sm mt-1 mb-2 nlfj" onChange={(e) => {
                                        handleChange(e)
                                    }}>
                                        <option value=""></option>
                                        {jobContacts.map(x => {
                                            return (
                                                <option value={x.contact_id}>{x.first_name} {x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <Editor
                                    editorStyle={{ borderStyle: 'solid', minHeight: '100px' }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </div>
                            <div className="form-group">
                                <label>Comments To Vendor</label>
                                <textarea rows={1} name="vendorComments" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Comments to Vendor" />
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-3">
                                    <label>Type</label>
                                    <select name="jobType" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} >
                                        <option value="">Select One</option>
                                        <option value="C">C (Contract)</option>
                                        <option value="C2H">C2H (Contract To Hire)</option>
                                        <option value="FL">FL (Freelance)</option>
                                        <option value="H">(Hire)</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Duration</label>
                                    <input type="text" name="duration" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} placeholder="Duration" />
                                </div>
                                <div className="form-group col-3">
                                    <label>Openings</label>
                                    <input type="text" name="openings" value={jobData.openings} className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} placeholder="Openings" />
                                </div>
                                <div className="form-group col-3">
                                    <label>Start Date</label>
                                    <input type="date" name="startDate" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} placeholder="Start Date" />
                                </div>
                            </div>

                            <div className="row ncof">
                                <div className="form-group col-4">
                                    <label>Client Job ID</label>
                                    <input type="text" name="client_job_id" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Client Job ID" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Bill Rate:</label>
                                    <input type="text" name="billRate" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Bill Rate" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Pay Rate:</label>
                                    <input type="text" name="payRate" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Pay Rate" />
                                </div>
                            </div>

                            <div className="row ncof">
                                <div className="form-group col-4">
                                    <label>Work Authorization</label>
                                    <input type="text" name="work_authorization" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} placeholder="Work Authorization" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Recruiter*</label>
                                    <select name="recruiter" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} required>
                                        <option value={props.access.user_id}>{props.access.user_name}</option>    {users.map(x => {
                                            return (
                                                <option value={x.user_id}>{x.user_name}-{x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-4">
                                    <label>Owner*</label>
                                    <select name="owner" className="form-control form-control-sm mt-1 mb-2 nlfw" onChange={(e) => handleChange(e)} required>

                                        <option value={props.access.user_id}>{props.access.user_name}</option>
                                        {users.map(x => {
                                            return (
                                                <option value={x.user_id}>{x.user_name}-{x.last_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-3">
                                    <label>Priority*</label>
                                    <select name="priority" value={jobData.priority} className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} required>
                                        <option selected={true} value="6 - Actively working">6 - Actively working</option>
                                        <option value="6 - Actively working">6 - Actively working</option>
                                        <option value="5 - Most Important">5 - Most Important</option>
                                        <option value="4 - Waiting for feedback">4 - Waiting for feedback</option>
                                        <option value="3 - Too late to submit">3 - Too late to submit</option>
                                        <option value="2 - Do not work on this">2 - Do not work on this</option>
                                        <option value="1 - Less Important">1 - Less Important</option>
                                    </select>
                                </div>

                                <div className="form-group col-3">
                                    <label>Hot*</label>
                                    <select name="is_hot" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} required>
                                        <option selected={true} value="0">No</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Public*</label>
                                    <select name="public" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => handleChange(e)} required disabled={jobData.status === "Internal" ? true : false}>
                                        <option selected={true} value="0">No</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                                <div className="form-group col-3">
                                    <label>Job Department</label>
                                    <select name="jobDepartment" className="form-control form-control-sm mt-1 mb-2 nlfwj" onChange={(e) => {
                                        handleChange(e)
                                    }}>
                                        <option value="">None</option>
                                        {jobDepartment.map(x => {
                                            return (
                                                <option key={x} value={x.company_department_id}>{x.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="row ncof">
                                <div className="form-group col-6">
                                    <label>Experience Needed</label>
                                    <textarea rows={1} name="experience_needed" className="form-control form-control-sm mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Experience Needed" />
                                </div>
                                <div className="form-group col-6">
                                    <label>BDM Comments</label>
                                    <textarea rows={1} name="bdmComments" className="form-control form-control-sm mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="BDM Comments" />
                                </div>
                            </div>
                            <div className="row ncof">
                                <div className="form-group col-6">
                                    <label>Submission Deadline</label>
                                    <input type="text" name="submissionDeadline" className="form-control form-control-sm mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Submission Deadline" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Internal Notes</label>
                                    <textarea rows={1} name="internalNotes" className="form-control form-control-sm mt-1 mb-2 nlf" onChange={(e) => handleChange(e)} placeholder="Internal Notes" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Key Skills(Enter skills separated with comma(,))*</label>
                                <textarea rows={1} name="keySkills" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChange(e)} placeholder="Key Skills" required={jobData.status !== 'Internal'} />
                            </div>
                            <div style={{ color: 'red' }}>
                                {message}
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit"  >Create Job</button>
                            </div>
                        </form>

                    </Modal>
                    <Modal
                        isOpen={jobModal}
                        onRequestClose={() => {
                            setJobModal(false)
                            getAllJobs()
                        }}
                        style={customStyles2}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>
                            <SingleJobOrder jobSlug={selectedJobOrderId} closeModal={(data) => {
                                setJobModal(data)
                                getAllJobs()
                                history.push('home?page=jobOrders&type=view')
                            }} />
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={companyAdd}
                        onRequestClose={() => {
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>New Company</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            setCompanyAdd(false)

                        }}>close</button>
                        <form className="form-group" onSubmit={(e) => addNewCompany(e)}>
                            <div className="form-group">
                                <label>Company Name*</label>
                                <input type="text" name="companyName" className="form-control form-control-sm mt-2 mb-2" placeholder="Company Name" onChange={(e) => handleCompanyChange(e)} required />
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Primary Phone</label>
                                    <input type="text" name="primaryPhone" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Primary Phone" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Secondary Phone</label>
                                    <input type="text" name="secondaryPhone" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Secondary Phone" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Address</label>
                                <textarea name="address" className="form-control form-control-sm mt-2 mb-2" placeholder="Address" onChange={(e) => handleCompanyChange(e)} />
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Country</label>
                                    <input type="text" name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="State" />
                                </div>
                                <div className="form-group col-4">
                                    <label>State</label>
                                    <input type="text" name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="State" />
                                </div>
                                <div className="form-group col-4">
                                    <label>City</label>
                                    <input type="text" name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="City" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" name="postalCode" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Fax Number</label>
                                    <input type="text" name="faxNumber" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Fax Number" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Web Site</label>
                                    <textarea name="website" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} placeholder="Web Site" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Company</label>
                                    <select name="isHot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleCompanyChange(e)} >
                                        <option value="">Select One</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" className="form-control form-control-sm mt-2 mb-2" placeholder="Notes" onChange={(e) => handleCompanyChange(e)} />
                            </div>
                            <div className="form-group">
                                <label>Key Technologies</label>
                                <textarea name="keyTechnologies" className="form-control form-control-sm mt-2 mb-2" placeholder="Key Technologies" onChange={(e) => handleCompanyChange(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit" >Add Company</button>
                            </div>
                        </form>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={companyContact}
                        onRequestClose={() => {
                        }}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                    >
                        <label>New Contact</label>
                        <button className="btn btn-danger float-right mb-2" onClick={() => {
                            setCompanyContact(false)

                        }}>close</button>
                        <form className="form-group" onSubmit={(e) => addNewContact(e)}>
                            <div className="row" style={{ marginTop: '15px' }}>
                                <div className="form-group col-6">
                                    <label>First Name*</label>
                                    <input type="text" name="first_name" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="First Name" required />
                                </div>
                                <div className="form-group col-6">
                                    <label>Last Name*</label>
                                    <input type="text" name="last_name" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Company*</label>
                                    <input type="text" value={jobData.companyName} name="company_name" className="form-control form-control-sm mt-1 mb-2" readOnly />

                                    {/* <ReactSearchAutocomplete
                                        styling={{
                                            zIndex: 99,
                                            borderRadius: 0
                                        }}
                                        items={items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                    /> */}
                                </div>
                                <div className="form-group col-6">
                                    <label>Title*</label>
                                    <input type="text" name="title" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Last Name" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Department</label>
                                    <Select
                                        placeholder="Select Department"
                                        onChange={(selectedOption) => setData({ ...data, company_department_id: selectedOption.value })}
                                        options={departmentOptions}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Reports to</label>
                                    <Select
                                        placeholder="Select Reporting Manager"
                                        onChange={(selectedOption) => setData({ ...data, reports_to: selectedOption.value })}
                                        options={reportsOptions}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Email</label>
                                    <input type="email" name="email1" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Email" />
                                </div>
                                <div className="form-group col-6">
                                    <label>2nd Email</label>
                                    <input type="email" name="email2" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="2nd Email" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>Work Phone</label>
                                    <input type="text" name="phone_work" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Work Phone" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Cell Phone</label>
                                    <input type="text" name="phone_cell" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Cell Phone" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Other Phone</label>
                                    <input type="text" name="phone_other" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Other Phone" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <textarea name="address" className="form-control form-control-sm mt-2 mb-2" placeholder="Address" onChange={(e) => handleChangeContact(e)} />
                            </div>
                            <div className="row">
                                <div className="form-group col-4">
                                    <label>City</label>
                                    <input type="text" name="city" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="City" />
                                </div>
                                <div className="form-group col-4">
                                    <label>State</label>
                                    <input type="text" name="state" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="State" />
                                </div>
                                <div className="form-group col-4">
                                    <label>Country</label>
                                    <input type="text" name="country" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Country" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label>Postal Code</label>
                                    <input type="text" name="zip" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} placeholder="Postal Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label>Hot Contact</label>
                                    <select name="is_hot" className="form-control form-control-sm mt-1 mb-2" onChange={(e) => handleChangeContact(e)} >
                                        <option value="">Select One</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea name="notes" className="form-control form-control-sm mt-2 mb-2" placeholder="Notes" onChange={(e) => handleChangeContact(e)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-outline-dark btn-sm" type="submit"  >Add Contact</button>
                            </div>
                        </form>
                    </Modal>
                </div>
            </>
        )
    )
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '78%',
    },
};

const customStyles2 = {
    content: {
        top: '52%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: '85%'
    },
};

