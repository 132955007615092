export const OPEN_CATS_URL = "https://recrcatsapi.novisync.com"
//export const OPEN_CATS_URL = 'http://71.40.116.146:31180'
export const PARSING_URL = "https://parsingapi.novisync.com"
export const CLIENT_URL = "https://rcats.novisync.com"
export const VERSION = "4.0.1"
export const BUILD_DATE = "02-27-2025"
export const SECRET_CODE = 'd35ab669-835d-4e67-ae1a-92d41eb02a09'
export const RANKING_URL = 'https://rankingapi.novisync.com'
export const TASK_URL = 'https://api-single.agile24x7.com/task_pg_prod'
//commented for testing
